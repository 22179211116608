import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import Button from 'components/Core/Button';
import HyperLink from 'components/Core/HyperLink';
import EmbedForm from 'components/Tools/EmbeddableForm';
import EmbedLink from 'components/Tools/EmbedLink';
import NumberInputField from 'components/Core/NumberInputField';
import GatedSubscribeForm from 'components/Tools/GatedSubscribeForm';
import useBreakpointView from 'utils/useBreakpointView';
import { getFormValid, blurFocusAndScroll, checkFormFieldValidate } from 'utils/formUtils';
import { FormContainer } from '../../styles';

const RefrigerationBTUForm = ({ gatedForm, ...otherProps }) => {
  const [gateFormCookieIncrease, setGateFormCookieIncrease] = useState(0);
  const [btuResult, setBTUResult] = useState(0);
  const resultRef = useRef(null);
  const isMobile = useBreakpointView(['xs', 'sm']);

  const calcPipeAll = (values) => {
    const result = values.area_volume * values.temperature_difference * 0.24;
    setBTUResult(result);
    setGateFormCookieIncrease(gateFormCookieIncrease + 1);
  };

  const formik = useFormik({
    initialValues: {
      area_volume: 0,
      temperature_difference: 0,
    },
    validate: (values) => {
      return {
        ...(!checkFormFieldValidate(values.area_volume) && {
          area_volume: 'Area Volume is invalid',
        }),
        ...(!checkFormFieldValidate(values.temperature_difference, true) && {
          temperature_difference: 'Temperature Difference is invalid',
        }),
      };
    },
    onSubmit: (values) => {
      blurFocusAndScroll(resultRef, isMobile);
      calcPipeAll(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <div className="p-[20px] bg-white shadow-[0_15px_40px_rgba(0,0,0,0.1)] flex flex-col md:flex-row md:p-[40px]">
            <div className="flex-1 py-0 px-0 md:px-[28px] mb-[20px] md:mb-0">
              <GatedSubscribeForm gatedForm={gatedForm} increaseCookie={gateFormCookieIncrease}>
                <div className="mb-[5px]">
                  <NumberInputField
                    id="area_volume"
                    label="Area Volume (cubic feet)"
                    min={1}
                    max={99999}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </div>
                <div className="mb-[5px]">
                  <NumberInputField
                    id="temperature_difference"
                    label="Temperature Difference  (℉)"
                    min={-100}
                    max={300}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </div>
                <Button shadow={false} onClick={() => formik.submitForm()} className="!w-full">
                  Calculate
                </Button>
                <EmbedLink
                  show={!otherProps.isEmbed}
                  openCopyEmbedLinkDialog={otherProps?.openCopyEmbedLinkDialog}
                  classNames="!hidden md:!flex items-center justify-center mt-[20px]"
                />
              </GatedSubscribeForm>
            </div>
            <div
              ref={resultRef}
              className="flex-1 p-[30px] bg-[var(--grey-2)] flex flex-col items-center justify-start h-full"
            >
              <h4 className="text-[16px] font-black text-[var(--grey-8)] mb-0">RESULTS</h4>
              <p className="text-[20px] font-['Nunito Sans'] text-black mb-0 mt-[20px]">
                Cooling Capacity:
              </p>
              <h4 className="text-[28px] font-extrabold text-[var(--darkest)] mb-0 mt-[10px]">
                {btuResult.toLocaleString()} BTUs
              </h4>
              <p className="mt-[40px] mb-0">
                Learn how we calculated this result{' '}
                <HyperLink href="#section-tool-page-content--container">below</HyperLink>.
              </p>
            </div>
            <EmbedLink
              show={!otherProps.isEmbed}
              openCopyEmbedLinkDialog={otherProps?.openCopyEmbedLinkDialog}
              classNames="!flex md:!hidden items-center justify-center mt-[20px]"
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

const RefrigerationBTUCalcForm = EmbedForm(RefrigerationBTUForm);
export default RefrigerationBTUCalcForm;
