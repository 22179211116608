import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import RefrigerationBTUCalcForm from 'components/Tools/RefrigerationBTUCalculator/Form';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import BookmarkContext from 'utils/BookmarkContext';
import { createToolsBreadcrumbs } from './utils/createToolsBreadcrumbs';

const RefrigerationBTUCalculator = (props) => {
  const title = 'Refrigeration BTU Calculator';
  const data = props.data.contentfulTool;
  const breadcrumbs = createToolsBreadcrumbs(title);
  const bookmarkData = {
    title: data.internalName,
    image: data?.cardImage?.image,
    subTitle: 'Tool',
    url: props.path,
    type: 'tool',
  };
  const pathUrl = props.location;

  return (
    <BookmarkContext.Provider value={bookmarkData}>
      <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
        <Hero data={data.hero} />
        <RefrigerationBTUCalcForm pathUrl={pathUrl} gatedForm={data.gatedForm} />
        <DescriptionBlock data={data.description} />
      </ToolboxLayout>
    </BookmarkContext.Provider>
  );
};

export default RefrigerationBTUCalculator;

export const RefrigerationBTUCalculatorQuery = graphql`
  query RefrigerationBTUCalculatorQuery {
    contentfulTool(internalName: { eq: "Refrigeration BTU Calculator" }) {
      ...Tool
    }
  }
`;
